import React from 'react';
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from 'react-oidc-context';

import {
  CenteredSpinner,
  ScrollToTop,
} from '../../components';

//import userManager from '../../utils/userManager';

const AuthRoute = ({
  children,
  user,
  noScroll,
}) => {
  // when the user is undefined, we are still loading the user
  if (user === undefined) {
    return (
      <CenteredSpinner>
        <p>Your account is loading.</p>
        <p>Please wait.</p>
      </CenteredSpinner>
    );
  }
  if (noScroll) {
    return children;
  }
  return (
    <ScrollToTop>
      {children}
    </ScrollToTop>
  );
};

AuthRoute.propTypes = {
  user: PropTypes.shape({
    expired: PropTypes.bool,
  }),
  children: PropTypes.node.isRequired,
  noScroll: PropTypes.bool,
};

AuthRoute.defaultProps = {
  user: undefined,
  noScroll: false,
};

export default withAuthenticationRequired(AuthRoute, {
  signinRedirectArgs: {
    state: { postPath: window.location.pathname },
  },
  onRedirecting: () => (
    <CenteredSpinner>
      <p>Please wait while we direct you to the login page.</p>
    </CenteredSpinner>
  ),
});
