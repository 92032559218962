import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Elements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

import httpCli from '../../../utils/httpCli';
import stripePromise, { stripeOptions } from '../../../utils/stripe';
import {
  CenteredSpinner,
} from '../../../components';

import SetupPurchaseForm from './SetupPurchaseForm';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SetupPurchase = ({
  user,
  assignedProducts,
  updateUser,
  broadcastError,
}) => {
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ clientSecret, setClientSecret ] = useState('');

  useEffect(() => {
    if (assignedProducts.length > 0) {
      navigate('/dashboard/setup/bio');
    } else {
      setIsLoading(true);
      httpCli
        .post('/purchase')
        .then((response) => {
          setClientSecret(response.data.clientSecret);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          broadcastError(error.response || error);
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
  }, []);

  if (isLoading || !clientSecret) {
    return (
      <CenteredSpinner>
        <p>Preparing your purchase</p>
        <p>Please wait.</p>
      </CenteredSpinner>
    );
  }

  return (
    <Container>
      <Elements
        stripe={stripePromise}
        options={{
          ...stripeOptions,
          clientSecret,
        }}
      >
        <SetupPurchaseForm
          assignedProducts={assignedProducts}
          user={user}
          updateUser={updateUser}
        />
      </Elements>
    </Container>
  );
};

export default SetupPurchase;
