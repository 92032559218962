import { connect } from 'react-redux';
import { filter } from 'lodash';

import SetupComponent from '../components/SetupPurchase';
import { updateUser } from '../../../actions/userActions';
import { broadcastFatalError } from '../../../actions/errorActions';

const mapStateToProps = (state) => ({
  user: state.user.profile,
  assignedProducts: filter(state.assignedProduct.list, { userId: state.user.profile.id }),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (values) => (
    dispatch(updateUser(values))
  ),
  broadcastError: (error) => (
    dispatch(broadcastFatalError(error))
  ),
});

const SetupPurchaseContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupComponent);

export default SetupPurchaseContainer;
