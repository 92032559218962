import React, { useState } from 'react';
import styled from 'styled-components';
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";

import {
  Fade,
  H1,
  Button,
} from '../../../components';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const SetupPurchase = ({
  user,
  updateUser,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  // const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ message, setMessage ] = useState('');

  const handleAddressChange = (e) => {
    setMessage('');
    if (e.complete) {
      updateUser({
        id: user.id,
        address: e.value.address,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const addressElement = elements.getElement('address');
    const {
      complete: addressComplete,
      value: address,
    } = await addressElement.getValue();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!addressComplete) {
      setMessage('Please complete the address form.');
      return;
    }

    setMessage('');

    updateUser({
      id: user.id,
      address: address.address,
    });

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/dashboard/setup/purchase-complete`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  const content = (
    <Container>
      <div
        style={{
          marginRight: "1rem",
        }}
      >
        <p>Billing Address</p>
        <AddressElement
          options={{ mode: 'billing' }}
          onChange={handleAddressChange}
        />
      </div>
      <form
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <p>Payment Information</p>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Button
          type="submit"
          disabled={isLoading || !stripe || !elements}
          busy={isLoading}
          style={{
            width: "100%",
            marginTop: "1rem",
          }}
        >
          Purchase
        </Button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
      <p>One time purchase of $29.99 USD</p>
    </Container>
  );

  return (
    <Wrapper>
      <Fade
        appear
      >
        <div>
          <H1>PURCHASE</H1>
          {content}
        </div>
      </Fade>
    </Wrapper>
  );
};

export default SetupPurchase;
